export const adminRoutes = [

  //admin-login
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import(/* webpackChunkName: "admin-login" */ '@/admin-view/auth/Login.vue'),
    meta: {
      title: 'Admin Login',
      isAdmin: true
    },
  },

  // client
  {
    path: '/client',
    name: 'admin-client',
    component: () => import(/* webpackChunkName: "clients" */ '@/admin-view/clients/Clients.vue'),
    meta: {
      title: 'Clients',
      isAdmin: true
    },

    children: [
      //reports
      {
        path: 'report/:clientId',
        name: 'admin-report',
        component: () => import(/* webpackChunkName: "report" */ '@/admin-view/reports/Reports.vue'),
      },

      // functions
      {
        path: 'function/:clientId',
        name: 'admin-function',
        component: () => import(/* webpackChunkName: "function" */ '@/admin-view/clients/Functions.vue'),
      },

      //billing
      {
        path: 'admin-billing/:clientId',
        name: 'admin-billing',
        component: () => import(/* webpackChunkName: "admin-billing" */ '../admin-view/billing/Billings.vue'),
      },

      //client-document
      {
        path: 'client-documents/:clientId',
        name: 'client-doc',
        component: () => import(/* webpackChunkName: "admin-billing" */ '../admin-view/clients/ClientDoc.vue'),
      },

      //admin-projects
      {
        path: 'admin-projects/:clientId',
        name: 'admin-projects',
        component: () => import(/* webpackChunkName: "admin-projects" */ '../admin-view/projects/AdminProjects.vue'),

        children: [
          {
            path: 'abc/:projId',
            props: true,
            name: 'each-admin-proj',
            component: () => import(/* webpackChunkName: "EachAdminProj" */ '../admin-view/projects/each-proj/EachAdminProj.vue'),
            meta: {
              title: 'Projects'
            },
            redirect: { name: 'proj-financials' },

            children: [
              {
                path: 'financials',
                name: 'proj-financials',
                component: () => import(/* webpackChunkName: "proj-financials" */ '../admin-view/projects/each-proj/ProjFinancials.vue')
              },

              {
                path: 'milestones',
                name: 'proj-milestones',
                component: () => import(/* webpackChunkName: "proj-milestones" */ '../admin-view/projects/each-proj/ProjMilestones.vue'),
              },

              {
                path: 'documents',
                name: 'proj-documents',
                component: () => import(/* webpackChunkName: "proj-documents" */ '../admin-view/projects/each-proj/ProjDocuments.vue'),
              },
            ]
          }
    
        ]
      },

      // setting
      {
        path: 'settings/:clientId',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/admin-view/settings/Settings.vue'),
        meta: {
          title: 'Settings',
          isAdmin: true
        },
      },
    ]
  },

  // admin-doc
  {
    path: '/admin-document',
    name: 'admin-doc',
    component: () => import(/* webpackChunkName: "admn-doc" */ '@/admin-view/document/AdminDoc.vue'),
    meta: {
      title: 'Document',
      isAdmin: true
    },
  },

 
];