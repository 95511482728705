import axios from 'axios';
import { ElNotification } from 'element-plus';

export default {
  namespaced: true,
  
  state: {
    loading: false,
    error: null,

    //project summary
    summaryArray: {},

    transactionData: [],

    //milestone
    mStoneArray: [],

    meta: {
      total: 0,
      page: 1,
      pages: 1,
      limit: 10,
    },

    //projects
    projectsArray: [],
    currentProjectArray: null
  },

  getters: {
    IS_LOADING: state => state.loading,
    GET_ERROR: state => state.error,
    PROJ_LOADING(state) {
      return state.loading;
    },

    GET_SUMMARY(state) {
      return state.summaryArray;
    },

    GET_TRANSACTION(state) {
      return state.transactionData;
    },

    GET_MILESTONE(state) {
      return state.mStoneArray;
    },

    GET_PROJECTS(state) {
      
      return state.projectsArray;
    },

    GET_META(state) {
      return state.meta;

    },

    GET_CURRENT_PROJECT(state) {
      
      return state.currentProjectArray;
    }
  },

  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_PROJECTS(state, payload) {
      state.projectsArray = payload;
    },

    SET_TRANSACTION( state, payload) {
      state.transactionData = payload;
    },

    SET_SUMMARY( state, payload) {
      state.summaryArray = payload;
    },

    SET_MILESTONES( state, payload, meta) {
      const existingIds = state.mStoneArray.map(m => m.id);
      const newMilestones = payload.filter(m => !existingIds.includes(m.id));
      state.mStoneArray = [...state.mStoneArray, ...newMilestones];
      state.meta = meta; 
    },

    SET_META(state, payload){
        state.meta = payload
    },

    
    CLEAR_MILESTONES(state) {
      state.mStoneArray = [];
    },

    UPDATE_MILESTONES(state, newMilestones) {
      state.milestones = [...state.milestones, ...newMilestones];
    },

    SET_PROJECTS(state, payload) {
      state.projectsArray = payload;
      
    },

    SET_CURRENT_PROJECT(state, payload) {
      state.currentProjectArray = state.projectsArray.filter(proj => {
        return String(proj.projectId) === String(payload);
      })
     
    },
  },

  actions: {
 
    async FETCH_PROJ_TRANSAC({ state, commit, rootGetters}, projectId) {
      try{
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`update-projects/${projectId}/`, {
          headers: {
            Authorization: `Bearer ${token}` 
          }
        });

        const results = response.data.data

        console.log('proj transaction', results)
        console.log('proj transaction', results.description)
        

        // const newSummary = results.filter(summary =>
        //   !state.summaryArray.some(existingData => existingData.id === summary.id)
        // );

        commit('SET_TRANSACTION', results);
      } catch (error) {
        const errorMsg = "Failed to fetch project transaction";

        console.log('transaction', error)
        
        return { error: true, errorMsg };
      }
    },

    async UPDATE_PROJECT_STATUS({ commit, rootGetters }, { projectId, status }) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        
        const response = await axios.put(`project/${projectId}/update-status`,  {'status':status},  {headers: {
          Authorization: `Bearer ${token}` 
        }}); 
  
        if (response.status === 200) {
          commit('UPDATE_PROJECT_STATUS', { projectId, status }); 
          ElNotification({
            title: 'Success',
            message: response.data.message || 'Project status updated successfully',
            type: 'success',
          });
          return { success: true };
        } else {
          ElNotification({
            title: 'Error',
            message: response.data.message || 'Error updating project status',
            type: 'error',
          });
          return { success: false, errorMsg: response.data.message || 'Error updating project status' };
        }
      } catch (error) {
        ElNotification({
            title: 'Error',
            message: error.message || 'Error updating project status',
            type: 'error',
          });
        return { success: false, errorMsg: error.message || 'Error updating project status' };
      }
    },
   
    async FETCH_PROJ_SUMMARY({ state, commit, rootGetters}, projectId) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`projects/${projectId}/`, {
          headers: {
            Authorization: `Bearer ${token}` 
          }
        });

        const data = response.data;
        console.log('worksss', data)
        const results = {
          id: data.id,
          name: data.name,
          customerName: data.customer_name,
          customerEmail: data.customer_email,
          description: data.description,
          status: data.status,
          startDate: data.start_date,
          endDate: data.end_date,
          contractPrice: parseFloat(data.contract_price),
          chargeOrder: parseFloat(data.charge_order),
          totalIncome: parseFloat(data.contract_price) + parseFloat(data.charge_order),
          cost: parseFloat(data.cost),
          opCosts: parseFloat(data.operational_cost),
          totalCost: parseFloat(data.total_cost),
          totalExpensesCost: parseFloat(data.total_expenses_cost), 
          profit: parseFloat(data.profit),
          expenses: data.expenses,
          cogs_budget: data.cogs_budget,
          equipment_budget: data.equipment_budget,
          others_budget: data.others_budget,
          labour_budget: data.labour_budget

        };

        commit('SET_SUMMARY', results);
      } catch (error) {
        console.error('Error fetching summary:', error);
        return { error: true, errorMsg: "Failed to fetch summary." };
      }
    },
   
    async FETCH_PROJECTS ({ state, commit, rootGetters }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      try{
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get('projects', {
          headers: {
            Authorization: `Bearer ${token}` 
          }
        });

        const results = response.data.results;

        const newFolders = results.filter(proj =>
          !state.projectsArray.some(existingData => existingData.projectId === proj.projectId)
        );

        commit('SET_PROJECTS', results);

        return results
      } catch (error) {
        const errorMsg = "Failed to fetch projects.";
        
        return { error: true, errorMsg };
      } finally {
        commit('SET_LOADING', false);
      }

    },

    async FETCH_MILESTONE ({ state, commit, rootGetters}, {projectId, page=1}){
      try{
        const token = rootGetters['auth/GET_TOKEN'];

        const response = await axios.get(`projects/${projectId}/milestones/?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}` 
          }
        });

        const results = response.data.data.map(mStone => ({
          id: mStone.id,
          title: mStone.title,
          status: mStone.status,
          dueDate: mStone.due_date,
          priorityLvl: mStone.priority_level,
          
        }));

        const newFolders = results.filter(mStone =>
          !state.mStoneArray.some(existingData => existingData.id === mStone.id)
        );

        commit('CLEAR_MILESTONES');
        commit('SET_MILESTONES', results);
        commit('SET_META', response.data.meta)
      } catch (error) {
        const errorMsg = "Failed to fetch milestone.";
        console.log('milestone error', error)

        return { error: true, errorMsg };
      }
    }

  }
}