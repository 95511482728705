<template>
  <div class="btn-wrap">

    <!-- Btn icon -->
    <router-link 
    :to="{ name: routeLink }"
    class="nav-link" >


      <div class="nav-btn"
      :class="{ activeNav: isActiveRoute(routeLink) }"
      @mouseover="hovered = true"
      @mouseleave="hovered = false">

        <img 
        v-if="hovered || isActiveRoute(routeLink)"  
        :src="require(`@/assets/icons/${computedIconImg}.svg`)" :alt="title" 
        class="icon">

        <img v-else 
        :src="require(`@/assets/icons/${iconImg}.svg`)" :alt="title" 
        class="icon">
        
      </div>

      <!-- Btn title -->
      <div class="nav-title" >
        <p :class="{ activeTitle: isActiveRoute(routeLink) }">      {{title}}
        </p>
      </div>
    </router-link>

  </div>
</template>

<script>
export default {
  name: 'NavButtons',

  props: {
    routeLink: {
      type: String,
      required: true
    },

    aIconImg: {
      type: String,
      required: false,
    },

    iconImg: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      hovered: false
    }
  },

  computed: {
    computedIconImg() {
      return this.aIconImg || this.iconImg;
    }
  },

  methods: {
   isActiveRoute(routeLink) {
      return this.$route.matched.some(record => record.name === routeLink);
      
    },
 }
}
</script>

<style lang='scss' scoped>

  .material-symbols-rounded {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }

  .btn-wrap {
    @apply  max-w-[120px];

    .nav-link {
      @apply flex flex-col gap-3 p-1 items-center justify-center;

    

      .nav-btn {
        @apply  p-2 flex items-center justify-center w-[60px] h-[50px] bg-[#EAEBEB] rounded-xl;

        @screen xl {
          @apply w-[80px] h-[70px];
        }

        &.activeNav,
        &:hover {
          @apply bg-[#104438];
        }

        .icon {
          @apply w-[30px] h-[30px];
        }

      }

      .nav-title {
        p {
          @apply text-sm text-[#515252] font-medium;

        &.activeTitle,
        &:hover {
            @apply text-[#104438] font-semibold tracking-wide;
          }
        }
      }
    }
  }

</style>