import { createStore } from 'vuex'


//admin store
import adminProject from './adminStore/adminProject'

//client store
import formValid from './clientStore/formValid';
import auth from './clientStore/auth';
import document from './clientStore/document';
import project from './clientStore/project';
import billings from './billings';
import reports from './clientStore/reports'
import dashboard from './clientStore/dashboard'
import client from './adminStore/client';
import invoice from './adminStore/Invoice';
import admindoc from './adminStore/admindoc'; 
import financials from './adminStore/financials';
import settings from './adminStore/settings';
import adminReports from './adminStore/adminReports';





export default createStore({

  modules: {
    //admin store
    adminProject,
    client,
    invoice,
    admindoc,
    financials,
    settings,
    adminReports,


    //client store
    formValid,
    auth,
    document,
    project,
    billings,
    reports,
    dashboard
  },

  state: {
    globalLoading: null,

    windowWidth: null,
    mobile: null,
    mobileNav: null,
  },

  getters: {
    IS_LOADING(state) {
      return state.globalLoading;
    },

    IS_MOBILE(state) {
      return state.mobile;
    },
    MOBILE_NAV(state) {
      return state.mobileNav;
    }
  },

  mutations: {
    SET_LOADING(state, loading) {
      state.globalLoading = loading;
    },

    SET_WINDOW_WIDTH(state, width) {
      state.windowWidth = width;
    },
    SET_MOBILE(state, value) {
      state.mobile = value;
    },
  },
  
  actions: {
    CHECK_SCREEN({ commit }) {
      const windowWidth = window.innerWidth;
      commit('SET_WINDOW_WIDTH', windowWidth);
      if (windowWidth <= 1024) {
        commit('SET_MOBILE', true);
      } else {
        commit('SET_MOBILE', false);
      }
    },
  },
})
