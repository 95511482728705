import axios from 'axios';

export default {
  namespaced: true,

  state: {
    clients: [], 
    showClientModal: false, 
    selectedClient: null, 
    meta: {
      total: 0,
      page: 1,
      pages: 1,
      limit: 10,
    },
    clientName:null
  },

  getters: {
    GET_CLIENTS(state) {
      return state.clients; 
    },

    GET_META(state) {
      return state.meta; 
    },

    IS_CLIENT_MODAL_ACTIVE(state) {
      return state.showClientModal; 
    },

    GET_SELECTED_CLIENT(state) {
      return state.selectedClient; 
    },
    GET_CLIENT_NAME(state){
      return state.clientName
    },
  },

  mutations: {
    SET_CLIENTS(state, data) {
      state.clients = data.data; 
      state.meta = data.meta; 
    },

    TOGGLE_CLIENT_MODAL(state) {
      state.showClientModal = !state.showClientModal; 
    },

    SET_SELECTED_CLIENT(state, client) {
      state.selectedClient = client; 
    },

    CLEAR_SELECTED_CLIENT(state) {
      state.selectedClient = null; 
    },
    SET_CLIENT_NAME(state, name) {
      state.clientName = name;
    },
  },

  actions: {
    async FETCH_CLIENTS({ commit, rootGetters }, { page = 1 }) {
      commit('SET_LOADING', true, { root: true }); 

      try {
        const token = rootGetters['auth/GET_TOKEN']; 
        const response = await axios.get(`/clients/?page=${page}`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        commit('SET_CLIENTS', response.data); 
        commit('SET_LOADING', false, { root: true }); 
        
      } catch (error) {
        console.error('Error fetching clients:', error);
        commit('SET_LOADING', false, { root: true }); 
      }
    },

    async ADD_CLIENT({ dispatch }, { username, email, password }) {
      const clientData = {
          username: username,
          password: password,
          email: email,
      };
    
      try {
        const response = await axios.post('/auth/create-client/', clientData); 
        //dispatch('FETCH_CLIENTS', {page:1}); 
        return response.data; 
      } catch (error) {
        console.error('Error adding client:', error);
        throw error; 
      }
    },

    async UPDATE_CLIENT({ dispatch }, { id, clientData }) {
      try {
        await axios.put(`/clients/${id}`, clientData); 
        dispatch('FETCH_CLIENTS'); 
      } catch (error) {
        console.error('Error updating client:', error);
        throw error; 
      }
    },

    async DELETE_CLIENT({ dispatch }, id) {
      try {
        await axios.delete(`/clients/${id}`); 
        dispatch('FETCH_CLIENTS'); 
      } catch (error) {
        console.error('Error deleting client:', error);
        throw error; 
      }
    },
  },
};