import axios from 'axios';

export default {
  namespaced: true,

  state: {
    reportData: null,
    clientProjects:null,
    loading: false,
    error: null,
  },

  getters: {
    GET_REPORT_DATA(state) {
      return state.reportData;
    },
    GET_CLIENT_PROJECTS(state) {
        return state.clientProjects;
      },
    IS_LOADING(state) {
      return state.loading;
    },
    GET_ERROR(state) {
      return state.error;
    },
  },

  mutations: {
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData;
    },
    SET_CLIENT_PROJECTS(state, clientProjects) {
        state.clientProjects = clientProjects;
      },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },

  actions: {
    async FETCH_REPORT_DATA({ commit, rootGetters }, { userId, month, year }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      try {
        const token = rootGetters['auth/GET_TOKEN']; 
        const response = await axios.get(`/client/${userId}/reports/${month}/${year}`,{
            headers: {
                Authorization: `Bearer ${token}`,
              },
        });
        commit('SET_REPORT_DATA', response.data.data);
      } catch (error) {
        console.error('Error fetching report data:', error);
        commit('SET_ERROR', error.response.data);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async FETCH_CLIENT_PROJECTS({ commit, rootGetters }, clientId) {
        commit('SET_LOADING', true);
        commit('SET_ERROR', null);
  
        try {
          const token = rootGetters['auth/GET_TOKEN']; 
          const response = await axios.get(`/client/${clientId}/projects/`,{
              headers: {
                  Authorization: `Bearer ${token}`,
                },
          });
          commit('SET_CLIENT_PROJECTS', response.data.data);
          console.log('check', response.data.data)
        } catch (error) {
          console.error('Error fetching project data:', error);
          commit('SET_ERROR', error.response.data);
        } finally {
          commit('SET_LOADING', false);
        }
      },

    async SAVE_REPORT({ commit, rootGetters, dispatch }, reportData) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      try {
        const token = rootGetters['auth/GET_TOKEN']; 

        const response = await axios.post('/create-report/', reportData, {
        headers: {
            Authorization: `Bearer ${token}`,
            },
        });
        dispatch('FETCH_REPORT_DATA', { userId:reportData.user, month: reportData.month, year:reportData.year });
        
        commit('SET_REPORT_DATA', response.data);
      } catch (error) {
        console.error('Error saving report:', error);
        commit('SET_ERROR', error.response.data);
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};