     // src/plugins/router.js
     export default {
        install(Vue, store) {
          Vue.mixin({
            beforeCreate() {
              if (this.$options.store) {
                this.$options.store.registerModule('router', {
                  namespaced: true,
                  state: {
                    route: this.$route,
                  },
                });
              }
            },
          });
        },
      };