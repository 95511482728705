import axios from "axios";
export default {
  namespaced: true,

  state: {
    showInvoice: null,
    showActionModal: null,
    showEditInvoice: null,
    isActionModalActive: null,
    showPricingCalc: null,
    billingData: [],
    totalPaid: 0,
    totalPending: 0,
    invoices: [],
    next: null,
    previous: null,
    currentPage: 1,
  },

  getters: {
    IS_ACT_MOD_ACTIVE(state) {
      return state.showActionModal;
    },

    INVOICE_MODAL(state) {
      return state.showInvoiceModal;
    },

    PRICING_CALC(state) {
      return state.showPricingCalc;
    },

    EDIT_INVOICE(state) {
      return state.showEditInvoice;
    },

    GET_TOTAL_PAID(state) {
      return state.totalPaid;
    },

    GET_TOTAL_PENDING(state) {
      return state.totalPending;
    },

    GET_INVOICES(state) {
      return state.invoices;
    },

    GET_NEXT_PAGE(state) {
      return state.next;
    },

    GET_PREVIOUS_PAGE(state) {
      return state.previous;
    },

    GET_CURRENT_PAGE(state) {
      return state.currentPage;
    }
  },

  mutations: {
    TOGGLE_PRICING_CALC(state) {
      state.showPricingCalc = !state.showPricingCalc;
    },

    TOGGLE_EDIT_INVOICE(state) {
      state.showEditInvoice = !state.showEditInvoice;
    },

    TOGGLE_ACTION_MODAL(state) {
      state.showActionModal = !state.showActionModal;
    },

    TOGGLE_INVOICE(state) {
      state.showInvoiceModal = !state.showInvoiceModal;
    },

    SET_BILLINGS(state, payload) {
      state.totalPaid = payload.results.total_paid;
      state.totalPending = payload.results.total_pending;
      state.invoices = payload.results.invoices;
      state.next = payload.next;
      state.previous = payload.previous;
    },

    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_EDIT_INVOICE(state, value) {
      state.showEditInvoice = value;
    },

    SET_INVOICE(state, value) {
      state.showActionModal  = value;
    },
  },

  actions: {
    async FETCH_BILLINGS({ commit, rootGetters }, page = 1) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`user/invoices/?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const results = response.data;

        commit('SET_BILLINGS', results);
        commit('SET_CURRENT_PAGE', page);

      } catch (error) {
        console.error("Failed to fetch invoices", error);
      }
    },

    async FETCH_NEXT_PAGE({ dispatch, getters }) {
      if (getters.GET_NEXT_PAGE) {
        const nextPage = getters.GET_CURRENT_PAGE + 1;
        await dispatch('FETCH_BILLINGS', nextPage);
      }
    },

    async FETCH_PREVIOUS_PAGE({ dispatch, getters }) {
      if (getters.GET_PREVIOUS_PAGE) {
        const previousPage = getters.GET_CURRENT_PAGE - 1;
        await dispatch('FETCH_BILLINGS', previousPage);
      }
    },

    async CREATE_INVOICE({ commit, rootGetters, dispatch }, invoiceData) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.post('user-create-invoice/', invoiceData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Optionally, you can update the local state with the new invoice
        // commit('ADD_INVOICE', response.data);

        // Refresh the invoices list
        await dispatch('FETCH_BILLINGS');

        return { success: true, data: response.data };
      } catch (error) {
        console.error("Failed to create invoice", error);
        return { success: false, error: error.response?.data || "An error occurred while creating the invoice" };
      }
    },
  }
}
