import axios from 'axios';
import { format } from 'date-fns';

export default {
  namespaced: true,

  state: {
    financialsData: [],
  },

  getters: {
    GET_FINANCIALS_DATA(state) {
      return state.financialsData;
    },
  },

  mutations: {
    SET_FINANCIALS_DATA(state, payload) {
      state.financialsData = payload;
    },
  },

  actions: {


    async FETCH_FINANCIALS({ commit, rootGetters }, projId) {
      commit('SET_LOADING', true, { root: true });

      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`projects/${projId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const contents = response.data

        commit('SET_FINANCIALS_DATA', contents);
       
        commit('SET_LOADING', false, { root: true });

        return { success: true };
      } catch (error) {
        console.error('Failed to fetch fnancials:', error);
        commit('SET_LOADING', false, { root: true });
        return { error: true, errorMsg: 'Failed to fetch financials' };
      }
    },

    async UPDATE_FINANCIALS({ commit, rootGetters }, projData) {
      commit('SET_LOADING', true, { root: true });
      
      const payload = {};

    
    if (projData.contract_price !== undefined) {
      payload.contract_price = projData.contract_price;
    }
        if (projData.charge_order !== undefined) {
          payload.charge_order = projData.charge_order;
        }
        if (projData.expenses !== undefined) {
          payload.expenses = projData.expenses;
        }

    
          try {
            const token = rootGetters['auth/GET_TOKEN'];
            const response = await axios.put(`update-projects/${projData.id}/`, payload, {
              headers: {
                Authorization: `Bearer ${token}`,
                
              },
            });
        
            commit('SET_FINANCIALS_DATA', response.data);
            commit('SET_LOADING', false, { root: true });
        
            return { success: true };
          } catch (error) {
            console.error('Failed to update financials:', error);
            commit('SET_LOADING', false, { root: true });
            return { error: true, errorMsg: 'Failed to update financials' };
          }
        },


  },
};