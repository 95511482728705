import axios from 'axios';

export default {
  namespaced: true,

  state: {
    totalIncome: 0,
    incomeChange: 0,
    totalExpenses: 0,
    expensesChange: 0,
    pendingIncome: 0,
    pendingIncomeChange: 0,
    monthlyIncome: {},
    monthlyExpenses: {},
    upcomingMilestones: [],
    ongoingProjects: [],
  },

  getters: {
    GET_TOTAL_INCOME: state => state.totalIncome,
    GET_INCOME_CHANGE: state => state.incomeChange,
    GET_TOTAL_EXPENSES: state => state.totalExpenses,
    GET_EXPENSES_CHANGE: state => state.expensesChange,
    GET_PENDING_INCOME: state => state.pendingIncome,
    GET_PENDING_INCOME_CHANGE: state => state.pendingIncomeChange,
    GET_MONTHLY_INCOME: state => state.monthlyIncome,
    GET_MONTHLY_EXPENSES: state => state.monthlyExpenses,
    GET_UPCOMING_MILESTONES: state => state.upcomingMilestones,
    GET_ONGOING_PROJECTS: state => state.ongoingProjects,
  },

  mutations: {
    SET_DASHBOARD_DATA(state, payload) {
      state.totalIncome = parseFloat(payload.total_income) || 0;
      state.incomeChange = payload.income_change || 0;
      state.totalExpenses = parseFloat(payload.total_expenses) || 0;
      state.expensesChange = payload.expenses_change || 0;
      state.pendingIncome = parseFloat(payload.pending_income) || 0;
      state.pendingIncomeChange = payload.pending_income_change || 0;
      state.monthlyIncome = formatMonthlyData(payload.monthly_income);
      state.monthlyExpenses = formatMonthlyData(payload.monthly_expenses);
      state.upcomingMilestones = payload.upcoming_milestones || [];
      state.ongoingProjects = payload.ongoing_projects || [];
    }
  },

  actions: {
    async FETCH_DASHBOARD_DATA({ commit, rootGetters }) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get('user-dashboard/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        console.log('Dashboard data:', data);

        commit('SET_DASHBOARD_DATA', data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        return { error: true, errorMsg: 'Failed to fetch dashboard data' };
      }
    }
  }
};

// Helper function outside of mutations
function formatMonthlyData(data) {
  const formatted = {};
  for (const [key, value] of Object.entries(data)) {
    formatted[key] = parseFloat(value) || 0;
  }
  return formatted;
}

