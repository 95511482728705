import axios from 'axios';

export default {
  namespaced: true,

  state: {
    clientDetails: null, 
    loading: false, 
    error: null, 
  },

  getters: {
    GET_CLIENT_DETAILS(state) {
      return state.clientDetails;
    },
    IS_LOADING(state) {
      return state.loading;
    },
    GET_ERROR(state) {
      return state.error;
    },
  },

  mutations: {
    SET_CLIENT_DETAILS(state, clientDetails) {
      state.clientDetails = clientDetails; 
    },
    SET_LOADING(state, loading) {
      state.loading = loading; 
    },
    SET_ERROR(state, error) {
      state.error = error; 
    },
  },

  actions: {
    async FETCH_CLIENT_DETAILS({ commit, rootGetters }, clientId) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null); 

      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`client/${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        commit('SET_CLIENT_DETAILS', response.data); 
      } catch (error) {
        console.error('Error fetching client details:', error);
        commit('SET_ERROR', error.response.data);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    

    async UPDATE_CLIENT_DETAILS({ commit, rootGetters }, { clientData, clientId }) {
        commit('SET_LOADING', true);
        commit('SET_ERROR', null); 
      
        try {
          const token = rootGetters['auth/GET_TOKEN']; 
          const response = await axios.put(`client/${clientId}`, clientData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
      
          commit('SET_CLIENT_DETAILS', response.data); 
        } catch (error) {
          console.error('Error updating client details:', error);
          commit('SET_ERROR', error.response.data); 
        } finally {
          commit('SET_LOADING', false);
        }
      },
      
      // Helper method to convert file to Base64
      convertToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      },

      async CHANGE_CLIENT_PASSWORD({ commit, rootGetters }, passwordData) {
        commit('SET_LOADING', true);
        commit('SET_ERROR', null); 
  
        try {
          const token = rootGetters['auth/GET_TOKEN'];
          console.log('HH',passwordData)
          const response = await axios.post(`auth/admin/change-password/${passwordData.clientId}/`, {old_password: passwordData.oldPassword, new_password:passwordData.newPassword}, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
         
          if (response.status === 200) {
            return response.data;
          } else {
           
            commit('SET_ERROR', response.data);

            throw new Error(response.data); 
          }
        } catch (error) {
          console.error('Error changing client password:', error);
          commit('SET_ERROR', error.response.data);
        } finally {
          commit('SET_LOADING', false);
        }
      },
  },
};