<template>
  <div class="panel-nav-wrap" v-if="isNavVisible">

    <transition name="mobile-nav">

    <div class="panel-nav">

      <!-- logo -->
      <div class="logo-space">
        <img src="../../assets/logo/logo.png" alt="logo image">
      </div>

      <div class="nav-log">
        
        <!-- nav-buttons -->
        <div class="nav-btn-wrap">
          <nav-buttons 
            v-for="(navBtn,index) in navBtnArray"
            :key="index"
            :title= navBtn.navTitle
            :iconImg = navBtn.navIcon
            :aIconImg =  navBtn.activeNavIcon
            :routeLink = navBtn.navRoute
          />
        </div>

        <!-- logout -->
        <div class="logout-wrap">
          <div class="logout" @click="logout">
            <fa-icon :icon="['fas', 'arrow-right-from-bracket']" class="icon" />
            <p>Logout</p>
          </div>
        </div>
      </div>

    </div>

    </transition>
  </div>

  <div 
  v-show="!isMobile"
  class="toggle-nav" 
  @click="toggleNav"
  :class="{'left-[205px]': isNavVisible, 'left-0': !isNavVisible}">
    <span class="material-symbols-outlined">
      {{ isNavVisible ? 'arrow_left' : 'arrow_right' }}
    </span>
  </div>
  

</template>

<script>
import NavButtons from './NavButtons.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PanelNav',

  components: { NavButtons },

  data() {
    return {
      isNavVisible: false, 

      navBtnArray: [
        {
          navIcon: 'in_dashboard',
          activeNavIcon: 'a_dashboard',
          navTitle: 'Dashboard',
          navRoute: 'dashboard'
        },
        
        {
          navIcon: 'in_document',
          activeNavIcon: 'a_document',
          navTitle: 'Documents',
          navRoute: 'folder'
        },
        {
          navIcon: 'in_briefcase',
          activeNavIcon: 'a_briefcase',
          navTitle: 'Projects',
          navRoute: 'proj'
        },
        {
          navIcon: 'in_billing',
          activeNavIcon: 'a_billing',
          navTitle: 'Billings',
          navRoute: 'billing'
        },
        
        {
          navIcon: 'in_moneys',
          activeNavIcon: 'a_moneys',
          navTitle: 'Reports',
          navRoute: 'reports'
        },
        {
          navIcon: 'in_setting',
          activeNavIcon: 'in_setting',
          navTitle: 'Settings',
          navRoute: 'setting'
        },
        
      ]
    }
  },

  computed: {
    ...mapGetters(['IS_MOBILE']),

    isMobile() {
      return this.IS_MOBILE;
    },
  },

  mounted() {
    
    this.setInitialNavState();
    window.addEventListener('resize', this.setInitialNavState);
  },

  beforeDestroy() {
   
    window.removeEventListener('resize', this.setInitialNavState);
  },

  methods: {
    ...mapActions('auth', ['LOGOUT']),

    logout() {
      this.LOGOUT();
    },

    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
    },

    setInitialNavState() {
     
      this.isNavVisible = window.innerWidth >= 1200;
    }
  }
}
</script>

<style lang='scss' scoped>
.panel-nav-wrap {
  @apply px-4 py-8  text-[#515252] max-w-[250px] border-r-2 border-[#EAEBEB] shadow-md relative hidden;
  //height: 100vh;
  z-index: 2;

  @screen md{
    @apply block;
  }

  .panel-nav {
    @apply flex flex-col gap-y-14 justify-start content-center items-center h-full;

    .logo-space {
      @apply w-full flex items-center justify-center px-6;


      img {
        @apply object-cover w-[150px];
      }
    }

    .nav-log {
      @apply  flex flex-col gap-y-16 justify-between items-stretch h-full w-full;

      .nav-btn-wrap {
        @apply grid grid-cols-2 gap-3 justify-items-stretch items-center justify-center;
      }

      .logout-wrap {
        @apply border-t-2 border-[#EAEBEB] w-full justify-self-end;

        .logout {
          @apply flex flex-row gap-3 px-6 py-5 justify-start items-center bg-[#EAEBEB] mt-8 rounded-md;

          p {
            @apply text-sm font-semibold;
          }
        }
      }

    }

  }
}

.toggle-nav {
  @apply bg-[#104438] p-1 absolute top-1/2 h-14 justify-center items-center hidden;
  
  z-index: 3;

  @screen md {
    @apply flex;
  }

  span {
    @apply text-[18px] text-white;
  }
}

.nav-visible {
  transform: translateX(0);
  @apply block;
}

.nav-hidden {
  transform: translateX(-100%);
  @apply hidden;
}

// animation
.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: all 1s ease;
}

.mobile-nav-enter-from {
  transform: translateX(-250px);
}
.mobile-nav-enter-to {
  transform: translateX(0);
}
.mobile-nav-leave-to {
  transform: translateX(-250px);
}



</style>