import { uid } from 'uid';
import axios from 'axios';

export default {
  namespaced: true,

  state: {
    // activeDropdownId: null,
    totalIncome: 0,
    pendingIncome: 0,
    totalExpenses: 0,
    totalOwnersComp: 0,
    totalCogs: 0,
    monthlyIncome: {},
    monthlyExpenses: {},
    monthlyCogs: {},
    monthlyOwnersComp: {},
    cogs: [],
    expenses: [],
    ownersComp: [],

    cogsBreakdown: [],
    expensesBreakdown: [],
    ownersCompBreakdown: [],

    profitLossPercent: 0,
    



    activeDropdownIds: [],

    profitLoss: 0,

    faqDocs: [
      {
        id: uid(6),
        title: 'Cost of Goods sold',
        graphs: [
          'Our minimum loan amount is N200,000 and a Maximum amount of N5,000,000. Both depend on borrower’s repayment capacity, based on verifiable business inventory and other criteria.',
        ]
      },

      {
        id: uid(6),
        title: 'Expenses',
        graphs: [
          'Passport Photograph, Valid means of identification, current bills, BVN (This are the basis requirement, more documents are needed for SME loan).'
        ]
      },

      {
        id: uid(6),
        title: `Owner's Comp`,
        graphs: [
          "• To access Rockshield’s business loan facility;",

          "• You must have a viable business.",
          "• You must have a physical office or shop with visible inventory.",
          "• The business location must be within our bank’s lending area.",
          "• It must have been in business for a period of at least one year."
        ]
      },
    ],
  },

  mutations: {
    // setActiveDropdown(state, id) {
    //   state.activeDropdownId = id;
    // },

    // clearActiveDropdown(state) {
    //   state.activeDropdownId = null;
    // },
    SET_REPORT_DATA(state, payload) {
      state.totalIncome = parseFloat(payload.total_income) || 0;
      state.pendingIncome = parseFloat(payload.pending_income) || 0;
      state.totalExpenses = parseFloat(payload.total_expenses) || 0;
      state.totalCogs = parseFloat(payload.total_cogs) || 0;
      state.totalOwnersComp = parseFloat(payload.total_owners_comp) || 0;
      state.monthlyIncome = formatMonthlyData(payload.monthly_income);
      state.monthlyExpenses = formatMonthlyData(payload.monthly_expenses);
      state.monthlyCogs = formatMonthlyData(payload.monthly_cogs);
      state.monthlyOwnersComp = formatMonthlyData(payload.monthly_owners_comp);

      state.monthIncome = payload.total_income || 0;
      state.monthProfit = payload.profit_loss || 0;
      state.monthExpenses = payload.total_expenditure || 0;
      state.cogs = payload.cogs || [];
      state.expenses = payload.expenses || [];
      state.ownersComp = payload.owners_comp || [];
      state.profitLoss = parseFloat(payload.expenses_breakdown.profit_loss_amount || 0);
      state.profitLossPercent = payload.expenses_breakdown.profit_loss_percentage || 0;

    
      

      
    },

    SET_MONTH_REPORT(state, payload) {
      state.cogs = payload.cogs || [];
      state.expenses = payload.expenses || [];
      state.ownersComp = payload.owners_comp || [];
      state.monthProfit = payload.profit_loss_amount;
      state.profitLossPercent = payload.profit_loss_amount || 0;
      state.monthIncome = payload.total_income;
      state.monthExpenses = payload.total_expenditure;

      state.prevMonthIncome = payload.previous_month_total_income || 0;
      state.prevMonthExpenses = payload.previous_month_total_expenditure || 0;

      state.cogsBreakdown = payload.cogs || [];
      state.expensesBreakdown = payload.expenses || [];
      state.ownersCompBreakdown = payload.owners_comp || [];
      state.profitLossPercent = payload.profit_loss_percentage;
    },

    setActiveDropdown(state, id) {
      if (!state.activeDropdownIds.includes(id)) {
        state.activeDropdownIds.push(id);
      }
    },
  
    clearActiveDropdown(state, id) {
      state.activeDropdownIds = state.activeDropdownIds.filter(activeId => activeId !== id);
    }
  },

  getters: {
    FAQ_DOCS(state){
      return state.faqDocs;
    },
    // ACTIVE_DROPDOWN_ID(state) {
    //   return state.activeDropdownId;
    // }

    ACTIVE_DROPDOWN_ID: (state) => (id) => {
      return state.activeDropdownIds.includes(id);
    },
    GET_TOTAL_INCOME(state) {
      return state.totalIncome;
    },
    GET_PENDING_INCOME(state) {
      return state.pendingIncome;
    },
    GET_TOTAL_EXPENSES(state) {
      return state.totalExpenses;
    },
    GET_TOTAL_COGS(state){
      return state.totalCogs;
    },
    GET_TOTAL_OWNERS_COMP(state){
      return state.totalOwnersComp;
    },
    GET_MONTHLY_INCOME(state) {
      return state.monthlyIncome;
    },
    GET_MONTHLY_EXPENSES(state) {
      return state.monthlyExpenses;
    },
    GET_MONTHLY_OWNERS_COMP(state){
      return state.monthlyOwnersComp
    },
    GET_MONTHLY_COGS(state){
      return state.monthlyCogs
    },

    GET_MONTH_INCOME(state) {
      return state.monthIncome;
    },
    GET_MONTH_PROFIT(state) {
      return state.monthProfit;
    },

    GET_PROFIT_PERCENTAGE(state){
      return state.profitLossPercent
    },

    GET_MONTH_EXPENSES(state) {
      return state.monthExpenses;
    },

    GET_PREVIOUS_MONTH_INCOME(state) {
      return state.prevMonthIncome;
    },

    GET_PREVIOUS_MONTH_EXPENSES(state) {
      return state.prevMonthExpenses;
    },


    GET_COGS(state) {
      console.log('hmm', state.cogs)
      return state.cogs;
    },
    GET_EXPENSES(state) {
      return state.expenses;
    },
    GET_OWNERS_COMP(state) {
      return state.ownersComp;
    },

    GET_COGS_BREAKDOWN(state) {
      return state.cogsBreakdown;
    },
    GET_EXPENSES_BREAKDOWN(state) {
      return state.expensesBreakdown;
    },
    GET_OWNERS_COMP_BREAKDOWN(state) {
      return state.ownersCompBreakdown;
    },
    GET_PROFIT_LOSS(state) {
      return state.profitLoss;
    } 
  },

  actions: {
    async FETCH_REPORT_DATA({ commit, rootGetters }) {
      try {
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get('dashboard/report/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        console.log('Report data:', data);

        commit('SET_REPORT_DATA', data);


        
      } catch (error) {
        console.error('Error fetching report data:', error);
        return { error: true, errorMsg: 'Failed to fetch report data' };
      }
    },

    async FETCH_MONTH_REPORT({ commit, rootGetters }, { year, month }) {
      try {
        console.log('year', year)
        const token = rootGetters['auth/GET_TOKEN'];
        const response = await axios.get(`dashboard/report/${year}/${month}/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        console.log('Report data:', data);

        commit('SET_MONTH_REPORT', data);


        
      } catch (error) {
        console.error('Error fetching report data:', error);
        return { error: true, errorMsg: 'Failed to fetch report data' };
      }
    }



    
  },


  
}

function formatMonthlyData(data) {
  const formatted = {};
  for (const [key, value] of Object.entries(data)) {
    formatted[key] = parseFloat(value) || 0;
  }
  return formatted;
}

function formatMoney(value) {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}