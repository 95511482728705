<template>
  <div id="app">
    <!-- Non-mobile rendering -->
    <template v-if="!isTinyScreen">
      <transition name="scale-fade" mode="out-in">
        <div class="content-wrap" v-if="!notFound && isAuthenticated">

          <div class="mobile-navigation" v-show="!isAdmin && isMobile">
            <mobile-nav/>
          </div>

          <div class="app-wrap">

            <panel-nav v-if="!isAdmin"/>

            <admin-panel v-if="isAdmin && !isAdminAuthScreen"/>
            <router-view/>
          </div>

        </div>

        <router-view v-else/>
      </transition>
    </template>

    <!-- Mobile message -->
    <div v-else class="mobile-message">
      <div class="logo-space animate-pulse">
        <img src="../src/assets/logo/logo.png" alt="logo image">
      </div>
      <h2>Hello user, this app is not supported on small screen devices</h2>
      <p>To use this app, kindly use a device with a bigger screen ( preferably a tablet, laptop or monitor )</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminPanel from './components/Panel/adminPanel.vue'
import MobileNav from './components/Panel/MobileNav.vue'
import PanelNav from './components/Panel/PanelNav.vue'

export default {
  name: "app",

  components: {
    PanelNav,
    AdminPanel,
    MobileNav
  },

  data() {
    return {
      isTinyScreen: false,
      isAuthScreen: null,
      isAdminAuthScreen: null,
      isAdmin: null
    }
  },

  created () {
    this.$store.dispatch('auth/INIT_STORE').then(() => {
      this.loginRedirect();
    });

    this.checkTinyScreen();

    this.CHECK_SCREEN()
    
    window.addEventListener("resize", this.checkTinyScreen)
    window.addEventListener("resize", this.CHECK_SCREEN)
  },

  unmounted() {
    window.removeEventListener("resize", this.checkTinyScreen);
  },

  computed: {
    ...mapGetters('auth', ['IS_AUTH', 'NOT_FOUND', 'isClientAuth', 'isAdminAuth']),
    ...mapGetters(['IS_LOADING', 'IS_MOBILE']),

    isMobile() {
      return this.IS_MOBILE;
    },

    globalLoading() {
      return this.IS_LOADING;
    },

    notFound() {
      return this.NOT_FOUND;
    },

    isAuthenticated() {
      return this.IS_AUTH;
    }
  },

  watch: {
    $route(to, from) {
      console.log('Route changed from', from.name, 'to', to.name);
      this.checkAdmin();
      this.checkAuthRoute();
      this.loginRedirect();
    },
  },

  methods: {
    ...mapActions(['CHECK_SCREEN']),

    checkTinyScreen() {
      const windowWidth = window.innerWidth;
      
      if (windowWidth <= 380) {
        this.isTinyScreen = true;
      } else {
        this.isTinyScreen = false;
      }
    },

    checkAdmin() {
      this.isAdmin = this.$route.meta.isAdmin === true;
      this.isAdminAuthScreen = this.$route.name === 'admin-login';
    },

    checkAuthRoute() {
      this.isAuthScreen = ['login', 'sign-up', 'reset-password', 'not-found'].includes(this.$route.name);
    },

    loginRedirect() {
      
    }
  }
}
</script>

<style lang="scss">
  #app {
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    height: 100vh;
  }

  .content-wrap {
    @apply h-full;

    .app-wrap {
      @apply  flex flex-row gap-1 h-full w-full overflow-x-hidden;
    }
  }

  .mobile-navigation{
    @apply  w-full;
  }

  .mobile-message {
    @apply flex flex-col items-center justify-center px-4 text-center;
    height: 100vh;
    background-color: #0A2922;
    color: #fff;

    .logo-space {
      @apply flex items-center rounded-3xl justify-center bg-white p-6 mb-8;

      img {
        @apply object-cover w-[150px];
      }
    }

    h2{
      font-size: 18px;
      font-weight: 500;
    }

    p { 
      margin-top: 16px;
    }
  }

  .scale-fade-enter-active,
  .scale-fade-leave-active {
    transition: transform 1s ease, opacity 1s ease;
  }

  .scale-fade-enter-from {
    /* transform: scale(0.8); */
    transform: translateY(20px);
    opacity: 0;
  }

  //global style

  .each-header{
    @apply flex flex-col w-full gap-2 p-2  justify-start items-start;

    @screen md {
      @apply flex-row justify-between items-center;
    }

    .proj-name{
      @apply flex flex-row gap-6 items-center;

      .go-back {
        @apply cursor-pointer;
      }

      h2{
        @apply uppercase text-base font-medium tracking-wide;

        @screen lg {
          @apply text-lg;
        }

        .customer {
          @apply text-[#9A9A9A];
        }
      }
    }
  }

  .status-button {
    &::before {
      @apply w-[6px] h-[6px];
      content: "";
      border-radius: 100%;

      @screen lg {
        @apply w-[10px] h-[10px];
      }
      
    }

    @apply text-xs font-medium tracking-wide items-center justify-center gap-2 py-2 px-2 rounded-xl flex flex-row max-w-[80px];

    @screen md {
      @apply px-4 gap-4 max-w-full text-sm;
      //max-w-[150px] 
    }
  }

  .done {
    &::before {
      background-color: #33d69f;
    }
    color: #33d69f;
    background-color: rgba(51, 214, 160, 0.2);
  }

  .inprog {
    &::before {
      background-color: #ff8f00;
    }
    color: #ff8f00;
    background-color: rgba(255, 145, 0, 0.2);
  }

  .new {
    &::before {
      background-color: #2D9CDB;;
    }
    color: #2D9CDB;;
    //background-color: #999999
    background-color: rgba(45, 156, 219, 0.2);
  }

  /* Custom Scrollbar Styles */
  .move::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  .move::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }

  .move::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 3px; 
  }

  .move::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
  }

</style>
