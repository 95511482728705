export default {
  namespaced: true,

  state: {},

  mutations: {},

  getters: {},

  actions: {
    
    VALIDATE_DATA_INPUT({ commit }, payload) {

      const  { event, formData, property, regex } = payload;
      
      formData[property] = event.target.value.replace(regex, '');
    },

    VALIDATE_NESTED_DATA_INPUT({ commit }, payload) {
      const { event, formData, property, regex } = payload;
      const value = event.target.value.replace(regex, '');
      setNestedValue(formData, property, value);
    },

    VALIDATE_EMPTY_FIELDS({ state }, formData) {
     
      const emptyFields = Object.entries(formData)
        .filter(([fieldName, value]) => !value)
        .map(([fieldName]) => fieldName);

      const error = emptyFields.length > 0;

      return { emptyFields, error };
    },

    async VALIDATE_FORM_DATA({ commit }, formData) {
      try {
        const { emptyFields, error } = validateEmptyFields(formData);
        return { emptyFields, error };
      } catch (error) {
        console.error('Validation error:', error);
        throw new Error('Validation failed');
      }
    },
  },

 
}

// utility functions

function setNestedValue(obj, path, value) {
  const keys = path.split(/[\.\[\]]/).filter(key => key !== '');
  let current = obj;

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      if (!current[key]) {
        current[key] = isNaN(keys[index + 1]) ? {} : [];
      }
      current = current[key];
    }
  });
}

const validateEmptyFields = (formData) => {
  const emptyFields = [];

  // Helper function to recursively check fields
  const checkFields = (data, path = '') => {
    if (Array.isArray(data)) {
      
      // If data is an array, iterate through each item
      data.forEach((item, index) => {
        checkFields(item, `${path}[${index}]`);
      });
    } else if (typeof data === 'object' && data !== null) {

      // If data is an object, iterate through its keys
      Object.entries(data).forEach(([key, value]) => {
        const fieldPath = path ? `${path}.${key}` : key;
        if (!value) {
          emptyFields.push(fieldPath);
        } else {
          checkFields(value, fieldPath);
        }
      });
    }
  };

  // Start checking from the top-level formData
  checkFields(formData);

  const error = emptyFields.length > 0;
  return { emptyFields, error };
};