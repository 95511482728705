import axios from 'axios';

export default {
  namespaced: true,

  state: {
    projects: [], 
    milestones: [],
    meta: {
      total: 0,
      page: 1,
      pages: 1,
      limit: 10,
    },
    showProjectModal: false, 
    selectedProject: null, 
  },

  getters: {
    GET_PROJECTS(state) {
      return state.projects; 
    },
    GET_META(state) {
      return state.meta; 
    },
    IS_PROJECT_MODAL_ACTIVE(state) {
      return state.showProjectModal; 
    },
    GET_SELECTED_PROJECT(state) {
      return state.selectedProject; 
    },
    GET_MILESTONES(state) {
      return state.milestones
    }
  },

  mutations: {
    SET_PROJECTS(state, { data, meta }) {
      state.projects = data; 
      state.meta = meta; 
    },

    SET_MILESTONES(state, { data, meta }) {
      state.milestones = data; 
      state.meta = meta; 
    },
    TOGGLE_PROJECT_MODAL(state) {
      state.showProjectModal = !state.showProjectModal; 
    },
    SET_SELECTED_PROJECT(state, project) {
      state.selectedProject = project; 
    },
    CLEAR_SELECTED_PROJECT(state) {
      state.selectedProject = null; 
    },
  },

  actions: {
    async FETCH_PROJECTS({ commit, rootGetters }, { clientId, page = 1 }) {
      commit('SET_LOADING', true, { root: true }); 

      try {
        const token = rootGetters['auth/GET_TOKEN']; 
        const response = await axios.get(`projects/client/${clientId}/?page=${page}`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        commit('SET_PROJECTS', response.data); 
        commit('SET_LOADING', false, { root: true }); 
        
      } catch (error) {
        console.error('Error fetching projects:', error);
        commit('SET_LOADING', false, { root: true }); 
      }
    },

    async ADD_PROJECT({ dispatch }, projectData) {
      try {
        const response = await axios.post('/admin/projects', projectData); 
        dispatch('FETCH_PROJECTS'); 
        return response.data; 
      } catch (error) {
        console.error('Error adding project:', error);
        throw error; 
      }
    },

    async UPDATE_PROJECT({ dispatch }, { id, projectData }) {
      try {
        await axios.put(`/admin/projects/${id}`, projectData); 
        dispatch('FETCH_PROJECTS'); 
      } catch (error) {
        console.error('Error updating project:', error);
        throw error; 
      }
    },

    async DELETE_PROJECT({ dispatch }, id) {
      try {
        await axios.delete(`/admin/projects/${id}`); 
        dispatch('FETCH_PROJECTS'); 
      } catch (error) {
        console.error('Error deleting project:', error);
        throw error; 
      }
    },

    async FETCH_MILESTONES({ commit, rootGetters }, { projectId, page = 1 }) {
      commit('SET_LOADING', true, { root: true }); 

      try {
        const token = rootGetters['auth/GET_TOKEN']; 
        const response = await axios.get(`projects/${projectId}/milestones/?page=${page}`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        commit('SET_MILESTONES', response.data); 
        commit('SET_LOADING', false, { root: true }); 
        
      } catch (error) {
        console.error('Error fetching projects:', error);
        commit('SET_LOADING', false, { root: true }); 
      }
    },

  },
};