import axios from 'axios';

export default {
  namespaced: true,

  state: {
    invoices: [], 
    meta: { 
        total: 0,
        page: 1,
        pages: 1,
        limit: 10,
      },
    showInvoiceModal: false, 
    selectedInvoice: null, 
  },

  getters: {
    GET_INVOICES(state) {
      return state.invoices; 
    },

    GET_META(state) {
        return state.meta; 
      },

    IS_INVOICE_MODAL_ACTIVE(state) {
      return state.showInvoiceModal; 
    },

    GET_SELECTED_INVOICE(state) {
      return state.selectedInvoice; 
    },
  },

  mutations: {
    SET_INVOICES(state, invoices) {
      state.invoices = invoices; 
    },

    TOGGLE_INVOICE_MODAL(state) {
      state.showInvoiceModal = !state.showInvoiceModal; 
    },

    SET_SELECTED_INVOICE(state, invoice) {
      state.selectedInvoice = invoice; 
    },

    CLEAR_SELECTED_INVOICE(state) {
      state.selectedInvoice = null; 
    },

    SET_INVOICES(state, { data, meta }) {
        state.invoices = data;
        state.meta = meta; 
      },
  },

  actions: {
    async FETCH_INVOICES({ commit, rootGetters }, { clientId, page = 1 }) {
        commit('SET_LOADING', true, { root: true }); 
  
        try {
          const token = rootGetters['auth/GET_TOKEN']; 
          const response = await axios.get(`/admin/invoices/${clientId}/?page=${page}`, { 
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          commit('SET_INVOICES', response.data); 
          commit('SET_LOADING', false, { root: true }); 
          
        } catch (error) {
          console.error('Error fetching invoices:', error);
          commit('SET_LOADING', false, { root: true }); 
        }
      },

    async ADD_INVOICE({ dispatch }, invoiceData) {
      try {
        const response = await axios.post('/user-create-invoice', invoiceData); 
        dispatch('FETCH_INVOICES'); 
        return response.data; 
      } catch (error) {
        console.error('Error adding invoice:', error);
        throw error; 
      }
    },

    async UPDATE_INVOICE({ dispatch }, { id, invoiceData }) {
      try {
        await axios.put(`/invoices/${id}`, invoiceData); 
        dispatch('FETCH_INVOICES'); 
      } catch (error) {
        console.error('Error updating invoice:', error);
        throw error; 
      }
    },

    async DELETE_INVOICE({ dispatch }, id) {
      try {
        await axios.delete(`/invoices/${id}`); 
        dispatch('FETCH_INVOICES'); 
      } catch (error) {
        console.error('Error deleting invoice:', error);
        throw error; 
      }
    },
  },
};