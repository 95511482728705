import router from '@/router/index'

export default {

  namespaced: true,

  state: {
    notFound: false,
    token: null,
    userId: null,
    userType: null,
    clientName:null
  },

  getters: {

    GET_TOKEN(state) {
      return state.token;
    },

    GET_USER_ID(state) {
      return state.userId;
    },
    isAdminAuth: state => state.userType === 'ADMIN',
    isClientAuth: state => state.userType === 'CLIENT',

    IS_ADMIN: state => state.userType === 'ADMIN',
    IS_CLIENT: state => state.userType === 'CLIENT',

    IS_AUTH(state) {
      return !!state.token;
    },

    NOT_FOUND(state) {
      return state.notFound;
    }
  },

  mutations: {
    SET_USER(state, payload) {
      state.userType = payload;
    },

    SET_NOT_FOUND(state, value) {
      state.notFound = value;
    },

    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER_ID(state, userId) { 
      state.userId = userId;
    },
    SET_USER_TYPE(state, userType) {
      state.userType = userType;
    },
  

  },

  actions: {
    async ASSIGN_TOKEN({ commit }) {
      const token = localStorage.getItem('authToken');

      if (token) {
        commit('SET_TOKEN', token);
      }

      // setInterval(() => {
      //   if (isTokenExpired(state.token)) {
          
      //     commit('SET_TOKEN', null);
      //     localStorage.clearItem('authToken');
          
      //     router.push({ name: 'login' });
      //   }
      // }, 60000);
    },


    INIT_STORE({ commit }) {
      const token = localStorage.getItem('authToken');
      const userId = localStorage.getItem('userId');
      const userType = localStorage.getItem('userType');
      if (token) commit('SET_TOKEN', token);
      if (userId) commit('SET_USER_ID', userId);
      if (userType) commit('SET_USER_TYPE', userType);
    },

    async ASSIGN_USER_ID({ commit }) {
      const userId = localStorage.getItem('userId');
      if (userId) {
        commit('SET_USER_ID', userId);
      }
    },


    async LOGOUT({state, commit}) {
      if(state.token) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        commit('SET_TOKEN', null)
        commit('SET_USER_ID', null);
        commit('SET_USER_TYPE', null);
        router.push({ name: 'login' });

        commit('SET_TOKEN', null)
      }
    }
  },
}