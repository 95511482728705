<template>
  <div class="panel-nav-wrap" v-if="isNavVisible">

    <transition name="mobile-nav">

    <div class="panel-nav">

      <!-- logo -->
      <div class="logo-space">
        <img src="../../assets/logo/admin_logo.png" alt="logo image">
      </div>

      <div class="nav-log">
        <!-- nav-buttons -->
        <div class="nav-btn-wrap">
          <router-link 
          class="nav-btn"
          v-for="(nav, index) in navBtnArray"
          :key="index"
          :to="{name: nav.navRoute}"
          :class="{ activeNav: isActiveRoute(nav.navRoute) }">
            <span class="material-symbols-rounded">
              {{nav.navIcon}}
            </span>
            <h2>{{nav.navTitle}}</h2>
         </router-link>
        </div>

        <!-- logout -->
        <div class="logout-wrap">
          <div class="logout" @click="logout">
            <fa-icon :icon="['fas', 'arrow-right-from-bracket']" class="icon" />
            <p>Logout</p>
          </div>
        </div>
      </div>

    </div>

    </transition>
  </div>

  <div class="toggle-nav" @click="toggleNav"
  :class="{'left-[225px]': isNavVisible, 'left-0': !isNavVisible}">
    <span class="material-symbols-outlined">
      {{ isNavVisible ? 'arrow_left' : 'arrow_right' }}
    </span>
  </div>
  

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'adminPanel',

  components: {  },

  data() {
    return {
      isNavVisible: false, 

      navBtnArray: [
        {
          navIcon: 'person',
          navTitle: 'CLIENT',
          navRoute: 'admin-client'
        },
        {
          navIcon: 'description',
          navTitle: 'DOCUMENT',
          navRoute: 'admin-doc'
        },
      ]
    }
  },

  mounted() {
    
    this.setInitialNavState();
    window.addEventListener('resize', this.setInitialNavState);
  },

  beforeDestroy() {
   
    window.removeEventListener('resize', this.setInitialNavState);
  },

  methods: {
    ...mapActions('auth', ['LOGOUT']),

    isActiveRoute(routeLink) {
      return this.$route.matched.some(record => record.name === routeLink);
      
      // return this.$route.path.includes(routeLink);
    },

    logout() {
      this.LOGOUT();
    },

    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
    },

    setInitialNavState() {
     
      this.isNavVisible = window.innerWidth >= 1200;
    }
  }
}
</script>

<style lang='scss' scoped>
.panel-nav-wrap {
  @apply py-8  text-[#222222] bg-[#071D18] min-w-[250px] max-w-[270px] border-r-2 border-[#EAEBEB] shadow-md relative;
  //height: 100vh;
  z-index: 2;

  .panel-nav {
    @apply flex flex-col gap-y-14 justify-start content-center items-center h-full;

    .logo-space {
      @apply w-full flex items-center justify-center px-6;


      img {
        @apply object-cover w-[150px];
      }
    }

    .nav-log {
      @apply  flex flex-col gap-y-16 justify-between items-stretch h-full w-full;

      .nav-btn-wrap {
        @apply flex flex-col gap-1;

        .nav-btn {
          @apply flex flex-row gap-3 px-6 py-3 justify-start items-center text-white;

          h2{
            @apply text-lg tracking-wider
          }

          &.activeNav,
          &:hover {
            @apply bg-white text-[#222222];
          }
        }
      }

      .logout-wrap {
        @apply border-t-2 border-white  mx-4 justify-self-end;

        .logout {
          @apply flex flex-row gap-3 px-6 py-5 justify-start items-center bg-white mt-8 rounded-md;

          p {
            @apply text-sm font-semibold;
          }
        }
      }

    }

  }
}

.toggle-nav {
  @apply bg-[#fff] p-1 absolute top-1/2 h-14 flex justify-center items-center;
  
  z-index: 3;

  span {
    @apply text-[26px] text-[#071D18];
  }
}

.nav-visible {
  transform: translateX(0);
  @apply block;
}

.nav-hidden {
  transform: translateX(-100%);
  @apply hidden;
}

// animation
.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: all 1s ease;
}

.mobile-nav-enter-from {
  transform: translateX(-250px);
}
.mobile-nav-enter-to {
  transform: translateX(0);
}
.mobile-nav-leave-to {
  transform: translateX(-250px);
}



</style>